body, html{
  padding: 0;
  margin: 0;
}

.root {
  width: 100%;
}

.main {
  margin-top: 80px;
}

.app {
  height: 100%;
}

h3 {
  color: green;
  font-weight: bold;
  font-size: 28px;
  text-align: left;
}

h4 {
  text-align: left;
  font-weight: bold;
  margin: 20px 0 5px 0;
}

p {
  text-align: left;
  margin: 10px 0;
}
