.medverkande-container {
  padding-bottom: 20px;
}

.medverkande-container h2 {
  font-weight: bold;
  color: green;
}

.medverkande-container h3 {
  font-size: 24px;
  color: black;
}

.medverkande-container h4 {

}

.medverkande-container p {
  margin: 0;
  padding: 0;
}
