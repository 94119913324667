.historia-img-container {
  display: flex;
}

.historia-img-container .spacer {
  flex: 0.25;
}

.historia-img-container .historia-img {
  flex: 0.50;
}

.hist-col {
  padding: 0;
  text-align: left;
}


.historia-img {
  padding: 30px 0;
}
