.intro {
  border: 0;
  padding: 0;
  width: 100%;
}

.row {
  width: 100%;
  padding: 0;
  margin: 0;
}

.container {
  width: 100%;
  padding: 0;
  margin: 0;
}
