.upphov-profile-pic {
  text-align: center;
}

.ville {
  width: 250px;
}

.upphov-profile-text h2{
  color: green;
}
